import { useQueryClient } from "react-query";
import { useBillingAddressSelector } from "../../../Hooks/useBillingAddressSelector";
import { Session } from "../../../Types/SessionType";
import GreenButton from "../../GreenButton";
import BillingAddressSelector from "./BillingAddressSelector";

export type NoPaymentProps = {
  onClick: (session: Session) => void;
  style?: React.CSSProperties;
  sessionData: Session;
  setErrors: React.Dispatch<React.SetStateAction<string[]>>;
  setTakingPayment: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NoPayment = ({ onClick, style, sessionData, setErrors, setTakingPayment }: NoPaymentProps) => {
  const billingSelectorForm = useBillingAddressSelector(sessionData);
  const queryClient = useQueryClient();

  const showBillingAddressSelector = !sessionData.collectDeliveryDetails && sessionData.selectedBillingAddress === null;
  return (
    <>
      <GreenButton
        text="Complete Order"
        style={style}
        onClick={async () => {
          let updatedSession = sessionData;
          if (showBillingAddressSelector) {
            const { trigger } = billingSelectorForm.props.form;
            const valid = await trigger();

            if (!valid) {
              return;
            }
            const responseSession: Session | null = await billingSelectorForm.util.updateBillingDetails(setErrors);


            if (responseSession === null) {
              setTakingPayment(false);
              setErrors(["Failed to set billing address"]);
              return;
            }

            queryClient.setQueryData("session", responseSession);
            updatedSession = responseSession;
          }
          onClick(updatedSession);
        }}
      />
      {showBillingAddressSelector && <BillingAddressSelector {...billingSelectorForm.props} />}
    </>
  );
};
