import { Typography } from "@mui/material";
import { JSX } from "react";

interface CenterScreenMessageProps {
	icon: JSX.Element;
	message: string;
	subMessage?: string;
}

const CenterScreenMessage = ({ icon, message = "", subMessage = "" }: CenterScreenMessageProps) => {
	const style = {
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column" as const,
		marginTop: "10em",
	};
	return (
		<div style={style}>
			{icon}
			<Typography fontWeight={600} variant="h5" textAlign="center">
				{message}
			</Typography>
			<Typography fontWeight={400} variant="body2" textAlign="center">
				{subMessage}
			</Typography>
		</div>
	);
};

export default CenterScreenMessage;
